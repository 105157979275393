<template>
    <div class="custom-list-grid-standard">
        <div class="custom-list-grid-standard h-100 my-4">
            <div class="d-flex align-items-center py-2"
                style="background: #F0FFEF; border-radius: 5px;; border: 1px solid #027243;">
                <span class="material-symbols-outlined m-2" style="color: #027243; font-weight: 500;">
                    check_circle
                </span>
                <span class="m-2 w-75" style="color: #000000; font-weight: 600; font-size: 17px;">
                    Forms which are enabled here will be shown in <b>Documents & Forms</b> section of member.
                </span>
            </div>
        </div>
        <div class="rounded-3 my-4" style="border: 1px solid #D8D8DF;">
            <div class="px-4 py-2 d-flex justify-content-start rounded-3" style="background: #F5F5F6;">
                <h3 style="font-weight: 700;">General</h3>
            </div>
            <div class="bg-white px-4 py-2 d-flex justify-content-start rounded-3">
                <div class="d-flex flex-wrap">
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 144) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['144'].settingsId+''"
                            :id="dataObject['144'].settingsId+''"
                            v-model="dataObject['144'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 160) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['160'].settingsId+''"
                            :id="dataObject['160'].settingsId+''"
                            v-model="dataObject['160'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 159) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['159'].settingsId+''"
                            :id="dataObject['159'].settingsId+''"
                            v-model="dataObject['159'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 15) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['159'].settingsId+''"
                            :id="dataObject['15'].settingsId+''"
                            v-model="dataObject['15'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 175) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['175'].settingsId+''"
                            :id="dataObject['175'].settingsId+''"
                            v-model="dataObject['175'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 161) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['161'].settingsId+''"
                            :id="dataObject['161'].settingsId+''"
                            v-model="dataObject['161'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 169) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['169'].settingsId+''"
                            :id="dataObject['169'].settingsId+''"
                            v-model="dataObject['169'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 172) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['172'].settingsId+''"
                            :id="dataObject['172'].settingsId+''"
                            v-model="dataObject['172'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 158) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['158'].settingsId+''"
                            :id="dataObject['158'].settingsId+''"
                            v-model="dataObject['158'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 164) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['164'].settingsId+''"
                            :id="dataObject['164'].settingsId+''"
                            v-model="dataObject['164'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 154) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['154'].settingsId+''"
                            :id="dataObject['154'].settingsId+''"
                            v-model="dataObject['154'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 151) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['151'].settingsId+''"
                            :id="dataObject['151'].settingsId+''"
                            v-model="dataObject['151'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 163) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['163'].settingsId+''"
                            :id="dataObject['163'].settingsId+''"
                            v-model="dataObject['163'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 153) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['153'].settingsId+''"
                            :id="dataObject['153'].settingsId+''"
                            v-model="dataObject['153'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 152) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['152'].settingsId+''"
                            :id="dataObject['152'].settingsId+''"
                            v-model="dataObject['152'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 155) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['155'].settingsId+''"
                            :id="dataObject['155'].settingsId+''"
                            v-model="dataObject['155'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 156) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['156'].settingsId+''"
                            :id="dataObject['156'].settingsId+''"
                            v-model="dataObject['156'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 157) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['157'].settingsId+''"
                            :id="dataObject['157'].settingsId+''"
                            v-model="dataObject['157'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 179) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['179'].settingsId+''"
                            :id="dataObject['179'].settingsId+''"
                            v-model="dataObject['179'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 180) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['180'].settingsId+''"
                            :id="dataObject['180'].settingsId+''"
                            v-model="dataObject['180'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 181) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['181'].settingsId+''"
                            :id="dataObject['181'].settingsId+''"
                            v-model="dataObject['181'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 182) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['182'].settingsId+''"
                            :id="dataObject['182'].settingsId+''"
                            v-model="dataObject['182'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 183) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['183'].settingsId+''"
                            :id="dataObject['183'].settingsId+''"
                            v-model="dataObject['183'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 187) }}</span>
                        <Toggle
                            class="toggle-switch"
                            :name="dataObject['187'].settingsId+''"
                            :id="dataObject['187'].settingsId+''"
                            v-model="dataObject['187'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="rounded-3 my-4" style="border: 1px solid #D8D8DF;">
            <div class="px-4 py-2 d-flex justify-content-start rounded-3" style="background: #F5F5F6;">
                <h3 style="font-weight: 700;">Clinical Assessments</h3>
            </div>
            <div class="bg-white px-4 py-2 d-flex justify-content-start rounded-3">
                <div class="d-flex flex-wrap">
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 19) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['19'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['19'].settingsId+''"
                            :id="dataObject['19'].settingsId+''"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 150) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['150'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['150'].settingsId+''"
                            :id="dataObject['150'].settingsId+''"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 18) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['18'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['18'].settingsId+''"
                            :id="dataObject['18'].settingsId+''"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card" style="background:#4152B8; width: 350px">
                        <span class="label w-75 text-light">{{ getDetails('label', 178) }}</span>
                        <div class="w-25">
                            <label for="show-hide" class="d-flex justify-content-center align-items-center w-100 check-card cursor-pointer">
                                <input type="checkbox" name="" id="show-hide" class="position-absolute invisible" v-model="dataObject['178'].val">
                                <div class="d-flex justify-content-between align-items-center switch-card">
                                    <span class="w-75 switch-label show-toggle">Show</span>
                                    <span class="w-75 switch-label hide-toggle">Hide</span>
                                    <div class="mx-1 check-card-circle"></div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 17) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['17'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['17'].settingsId+''"
                            :id="dataObject['17'].settingsId+''"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 20) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['20'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['20'].settingsId+''"
                            :id="dataObject['20'].settingsId+''"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 16) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['16'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['16'].settingsId+''"
                            :id="dataObject['16'].settingsId+''"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="rounded-3 my-4" style="border: 1px solid #D8D8DF;">
            <div class="px-4 py-2 d-flex justify-content-start rounded-3" style="background: #F5F5F6;">
                <h3 style="font-weight: 700;">Standard Screening Tools</h3>
            </div>
            <div class="bg-white px-4 py-2 d-flex justify-content-start rounded-3">
                <div class="d-flex flex-wrap">
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 27) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['27'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['27'].settingsId+''"
                            :id="dataObject['27'].settingsId+''"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 21) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['21'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['21'].settingsId+''"
                            :id="dataObject['21'].settingsId+''"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 22) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['22'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['22'].settingsId+''"
                            :id="dataObject['22'].settingsId+''"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 29) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['29'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['29'].settingsId+''"
                            :id="dataObject['29'].settingsId+''"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 30) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['30'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['30'].settingsId+''"
                            :id="dataObject['30'].settingsId+''"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 31) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['31'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['31'].settingsId+''"
                            :id="dataObject['31'].settingsId+''"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 32) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['32'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['32'].settingsId+''"
                            :id="dataObject['32'].settingsId+''"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 34) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['34'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['34'].settingsId+''"
                            :id="dataObject['34'].settingsId+''"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 33) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['33'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['33'].settingsId+''"
                            :id="dataObject['33'].settingsId+''"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 24) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['24'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['24'].settingsId+''"
                            :id="dataObject['24'].settingsId+''"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 25) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['25'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['25'].settingsId+''"
                            :id="dataObject['25'].settingsId+''"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 36) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['36'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['36'].settingsId+''"
                            :id="dataObject['36'].settingsId+''"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="rounded-3 my-4" style="border: 1px solid #D8D8DF;">
            <div class="px-4 py-2 d-flex justify-content-start rounded-3" style="background: #F5F5F6;">
                <h3 style="font-weight: 700;">Addiction Treatment</h3>
            </div>
            <div class="bg-white px-4 py-2 d-flex justify-content-start rounded-3">
                <div class="d-flex flex-wrap">
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 37) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['37'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['37'].settingsId+''"
                            :id="dataObject['37'].settingsId+''"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 38) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['38'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['38'].settingsId+''"
                            :id="dataObject['38'].settingsId+''"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 39) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['39'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['39'].settingsId+''"
                            :id="dataObject['39'].settingsId+''"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 145) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['145'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['145'].settingsId+''"
                            :id="dataObject['145'].settingsId+''"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 146) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['146'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['146'].settingsId+''"
                            :id="dataObject['146'].settingsId+''"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 147) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['147'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['147'].settingsId+''"
                            :id="dataObject['147'].settingsId+''"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 45) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['45'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['45'].settingsId+''"
                            :id="dataObject['45'].settingsId+''"
                        />
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-1 py-2 card">
                        <span class="label">{{ getDetails('label', 46) }}</span>
                        <Toggle
                            class="toggle-switch"
                            v-model="dataObject['46'].val"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            :name="dataObject['46'].settingsId+''"
                            :id="dataObject['46'].settingsId+''"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="rounded-3 my-4" style="border: 1px solid #D8D8DF;">
            <div class="px-4 py-2 d-flex justify-content-start rounded-3" style="background: #F5F5F6;">
                <h3 style="font-weight: 700;">Toxicology Report</h3>
            </div>
            <div class="bg-white px-4 py-2 d-flex justify-content-start rounded-3 align-items-center">
                <label class="my-3 w-25" for="" style="font-size: 15px; font-weight: bold;">
                    {{ getDetails('label', 184) }}
                </label>
                <select class="my-3 w-50" name="" id="" style="line-height: 30px;" v-model="dataObject['184'].val">
                    <option :value="op.value" v-for="op in getDetails('value_options', 184)" :key="op.value">{{ op.text }}</option>
                </select>
            </div>
        </div>

        <div class="d-flex justify-content-end">
            <button class="border-3 save-btn" @click="saveChanges()">Save Changes</button>
        </div>
    </div>
</template>

<script>
    import { settings } from '@/util/apiRequests';

    export default {
        components: {},
        name: 'FormsSettings',
        props: {
            level: {
                type: String,
            },
            level_id: {
                type: Number,
                default: 1,
            },
            data_source: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                is_client_dependant: true
            };
        },
        methods: {
            getDetails(type, settingsId) {
                const index = this.data_source.findIndex(el => el.settings_id === settingsId);
                if (type === 'value_options') {
                    return this.data_source[index] && this.data_source[index][type] ? JSON.parse(this.data_source[index][type]) : [];
                }
                return this.data_source[index] ? this.data_source[index][type] : '';
            },
            async saveChanges() {
                ( this.dataObject['178'].val === true ) ? this.dataObject['178'].val = 0 : this.dataObject['178'].val = 1;
                let data = Object.values( this.dataObject );
                data = await Promise.all( data.map( async( el ) => {
                    if( !el.id ) 
                        el.id = await this.createSettingsData( this.level, this.level_id, el.settingsId, el.val );
                    return el;
                } ) )
                const endpoint = settings.updateMultipleSettings();
                const updateTagsResponse = await this.$api.put(endpoint, { updateRequests: data });
                if( updateTagsResponse.data.success ) {
                    this.$toasted.success("Forms Settings Updated !");
                }
                else {
                    this.$toasted.error("Failed to update forms settings !");
                }
            },
            async createSettingsData(level, levelId, settingsId, value) {
                const settingCreated = await this.$api.post(settings.createSettingData(level, levelId, settingsId), {val: value});
                return settingCreated.data.recId[0]
            },
        },
        async created() {
            const modifiedDataSource = this.data_source.map(el => {
                return {
                    settingsId: el.settings_id,
                    id: el.data_id,
                    val: el.value
                }
            })

            this.dataObject = {};
            modifiedDataSource.forEach(el => {
                Object.assign(this.dataObject, {
                    [el.settingsId]: el
                })
            })

            this.dataObject['178'].val = this.dataObject['178'].val == 0 ? true : false;
        },
    };
</script>
<style scoped>
    .card {
        width: 18%; 
        border: 1px solid #D8D8DF; 
        border-radius: 6px; 
        margin: 5px;
        flex-direction: row;
    }

    .save-btn {
        background: #5057C3;
        box-shadow: 0px 8px 22px 6px rgba(0, 0, 0, 0.24), inset 0px 4px 4px rgba(220, 220, 220, 0.25);
        color: white;
        width: 150px;
        height: 50px;
        font-family: Roboto;
        font-size: 16px;
        border-radius: 5px;
    }

    .label {
        font-size: 15px; 
        font-weight: 500; 
        font-family: 'Roboto'; 
        margin-left: 8px;
    }

    .switch-card {
        border: 2px solid #FFF; 
        border-radius: 20px; 
        height: 30px;
        background: #4152B8;
    }

    .switch-label {
        font-size: 15px; 
        font-weight: 500; 
        font-family: 'Roboto'; 
        margin-left: 8px;
    }
    
    .show-toggle {
        color: #000;
        display: none;
    }

    .hide-toggle {
        color: #FFF;
        display: block;
    }

    .check-card-circle {
        border-radius: 50%;
        border: 1px solid #4152B8;
        height: 22px; 
        width: 30px;
        background: #FFF;
    }
    
    .check-card input[type="checkbox"]:checked + .switch-card {
        background: #FFF;
        border: 2px solid #FFF; 
    }

    .check-card input[type="checkbox"]:checked + .switch-card .hide-toggle{
        display: none;
    }

    .check-card input[type="checkbox"]:checked + .switch-card .show-toggle{
        display: block;
    }

    .check-card input[type="checkbox"]:checked + .switch-card .check-card-circle{
        background: #4152B8;
        border: 1px solid #FFF;
    }
    
</style>