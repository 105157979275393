<template>
    <div style="font-family: 'Roboto';">
        <div class="custom-list-grid-standard h-100 my-4">
            <div class="d-flex align-items-center py-2"
                style="background: #F0FFEF; border-radius: 5px;; border: 1px solid #027243;">
                <span class="material-symbols-outlined m-2" style="color: #027243; font-weight: 500;">
                    check_circle
                </span>
                <span class="m-2 w-75" style="color: #000000; font-weight: 600; font-size: 17px;">
                    Select the corresponding template you would like sent to members when one of the automatic email invites
                    or reminders are created
                </span>
            </div>
        </div>
        <div class="custom-list-grid-standard h-100 my-4" style="border: 1px solid #D8D8DF">
            <div class="d-flex justify-content-start py-4 px-4 bg-light">
                <div class="d-grid w-50 px-4">
                    <label class="my-3" for="" style="font-size: 17px; font-weight: bold;">
                        {{ getDetails('label', 49) }}
                    </label>
                    <div>
                        <select class="my-3" :name="dataObject['49'].settingsId+''" :id="dataObject['49'].settingsId+''" style="line-height: 30px;" v-model="dataObject['49'].val">
                            <option :value="op.value" v-for="op in getDetails('value_options', 49)" :key="op.value">{{ op.text }}</option>
                        </select>
                    </div>
                </div>
                <div class="d-grid w-50 px-4">
                    <label class="my-3" for="" style="font-size: 17px; font-weight: bold;">
                        {{ getDetails('label', 51) }}
                    </label>
                    <div>
                        <select class="my-3" :name="dataObject['51'].settingsId+''" :id="dataObject['51'].settingsId+''" style="line-height: 30px;" v-model="dataObject['51'].val">
                            <option :value="op.value" v-for="op in getDetails('value_options', 51)" :key="op.value">{{ op.text }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center py-3 px-4 bg-light">
                <label for="" class="px-4 w-25" style="font-size: 15px; font-weight: 600;">{{ getDetails('label', 136) }}</label>
                <div>
                    <input type="number" min="0" :name="dataObject['136'].settingsId+''" :id="dataObject['136'].settingsId+''" v-model="dataObject['136'].val">
                </div>
            </div>
            <div class="d-flex align-items-center py-3 px-4 bg-light">
                <div class="d-grid">
                    <label for="" class="px-4 w-100" style="font-size: 15px; font-weight: 600;">{{ getDetails('label', 188) }} (Can select multiple options)</label>
                    <div class="d-flex justify-content-start my-4 flex-wrap">
                        <label :for="'radio'+op.value" class="d-grid rounded-2 mx-3 cursor-pointer check-card" v-for="op in getDetails( 'value_options', 188 )" :key="op.value">
                            <input type="checkbox" name="tablet_not" :id="'radio'+op.value" @change="handleApptNotification( op.value, $event )" :checked="getDetails( 'value', 188, true ).indexOf(op.value) >= 0">
                            <div class="d-flex p-4 flex-column justify-content-center align-items-center py-3" style="min-width: 250px;">
                                <span for="" class="label d-block my-2">{{ op.text }}</span>
                                <div class="unchecked-container my-2"></div>
                                <div class="container-active justify-content-center align-items-center my-2">
                                    <span class="material-symbols-outlined check-icon">check </span>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="custom-list-grid-standard h-100 my-4" style="border: 1px solid #D8D8DF">
            <div class="d-flex justify-content-start py-4 px-4 bg-light">
                <div class="d-grid w-50 px-4">
                    <label class="my-3" for="" style="font-size: 17px; font-weight: bold; width: 50%;">
                        {{ getDetails('label', 48) }}
                    </label>
                    <select class="my-3" :name="dataObject['48'].settingsId+''" :id="dataObject['48'].settingsId+''" style="line-height: 30px;" v-model="dataObject['48'].val">
                        <option :value="op.value" v-for="op in getDetails('value_options', 48)" :key="op.value">{{ op.text }}</option>
                    </select>
                </div>
                <div class="d-grid w-50 px-4 px-4">
                    <label class="my-3" for="" style="font-size: 17px; font-weight: bold; width: 50%;">
                        {{ getDetails('label', 50) }}
                    </label>
                    <select class="my-3" :name="dataObject['50'].settingsId+''" :id="dataObject['50'].settingsId+''" style="line-height: 30px;" v-model="dataObject['50'].val">
                        <option :value="op.value" v-for="op in getDetails('value_options', 50)" :key="op.value">{{ op.text }}</option>
                    </select>
                </div>
            </div>
            <div class="d-flex align-items-center py-3 px-4 bg-light">
                <label for="" class="px-4 w-25" style="font-size: 15px; font-weight: 600;">
                    {{ getDetails('label', 135) }}
                </label>
                <div>
                    <input type="number" min="0" :name="dataObject['135'].settingsId+''" :id="dataObject['135'].settingsId+''" v-model="dataObject['135'].val">
                </div>
            </div>
            <div class="d-flex align-items-center py-3 px-4 bg-light">
                <label for="" class="px-4 w-25" style="font-size: 15px; font-weight: 600;">
                    {{ getDetails('label', 177) }}
                </label>
                <div>
                    <input type="number" min="0" :name="dataObject['177'].settingsId+''" :id="dataObject['177'].settingsId+''" v-model="dataObject['177'].val">
                </div>
            </div>
        </div>
        <div class="custom-list-grid-standard h-100 my-4" style="border: 1px solid #D8D8DF">
            <div class="d-flex justify-content-start py-4 px-4 bg-light">
                <div class="d-grid px-4 w-100">
                    <label for="" style="font-size: 17px; font-weight: 600;">Choose Template for Account Balance Reminder</label>
                    <div class="d-flex justify-content-start my-4 flex-wrap">
                        <label :for="'radio'+op.value" class="d-grid rounded-2 mx-3 cursor-pointer radio-card" v-for="op in getDetails( 'value_options', 174 )" :key="op.value">
                            <input type="radio" name="template" :id="'radio'+op.value" :value="op.value" v-model="dataObject['174'].val">
                            <div class="d-flex p-4 flex-column justify-content-center align-items-center py-3" style="min-width: 250px;">
                                <span for="" class="label d-block my-2">{{ op.text }}</span>
                                <div class="unchecked-container my-2"></div>
                                <div class="container-active justify-content-center align-items-center my-2">
                                    <span class="material-symbols-outlined check-icon">check </span>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-end">
            <button class="border-3 save-btn" @click="saveChanges()">Save Changes</button>
        </div>
    </div>
</template>

<script>
import { settings } from '@/util/apiRequests';

export default {
    components: {},
    name: 'ClientNotificationSetting',
    props: {
        level: {
            type: String,
        },
        level_id: {
            type: Number,
            default: 1,
        },
        data_source: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            dataObject: null
        };
    },
    methods: {
        async saveChanges() {
            let data = Object.values( this.dataObject );
            data = await Promise.all( data.map( async( el ) => {
                if( !el.id ) 
                    el.id = await this.createSettingsData( this.level, this.level_id, el.settingsId, el.val );
                return el;
            } ) )
            const endpoint = settings.updateMultipleSettings();
            const updateTagsResponse = await this.$api.put(endpoint, { updateRequests: data });
            if( updateTagsResponse.data.success ) {
                this.$toasted.success("Member Notification Settings Updated !");
            }
            else {
                this.$toasted.error("Failed to update member notification settings !");
            }
        },
        getDetails(type, settingsId, jsonParse) {
            const index = this.data_source.findIndex(el => el.settings_id === settingsId);
            if (type === 'value_options') {
                return this.data_source[index] && this.data_source[index][type] ? JSON.parse(this.data_source[index][type]) : [];
            }
            if(type === 'value' && jsonParse) {
                if( jsonParse ) return JSON.parse(this.dataObject['188'].val);
                else return this.dataObject['188'].val;
            }
            return this.data_source[index] ? this.data_source[index][type] : '';
        },
        async createSettingsData(level, levelId, settingsId, value) {
            const settingCreated = await this.$api.post(settings.createSettingData(level, levelId, settingsId), {val: value});
            return settingCreated.data.recId[0]
        },
        handleApptNotification( timing, event ) {
            let timings = this.getDetails('value', 188, true);
            if( event.target.checked === true ) {
                timings.push( timing );
            }
            else {
                const i = timings.findIndex( el => el === timing );
                timings.splice( i, 1 );
            }
            this.dataObject['188'].val = JSON.stringify(timings);
        }
    },
    async created() {
        const modifiedDataSource = this.data_source.map(el => {
            return {
                settingsId: el.settings_id,
                id: el.data_id,
                val: el.value
            }
        })

        this.dataObject = {};
        modifiedDataSource.forEach(el => {
            Object.assign(this.dataObject, {
                [el.settingsId]: el
            })

            setTimeout(() => {
                const element = document.getElementById(el.settingsId);
                if( element ) {
                    document.getElementById(el.settingsId)
                    .addEventListener('input', e => {
                        const el = e.target || e

                        if(el.type == "number" && el.min ){
                            let value = parseInt(el.value)
                            el.value = value;
                            let min = parseInt(el.min)
                            if ( value < min ) el.value = el.min
                        }
                    });
                }
            }, 1000)
        })

    },
};
</script>
<style scoped>
.radio-card {
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15);
    color: #000;
    background: #fff;
    margin: 10px;   
}

.radio-card input[type="radio"]{
    position: absolute;
   visibility: hidden;
}

.radio-card .container-active{
    display: none;
}
.radio-card .unchecked-container{
    display: block;
}

.radio-card input[type="radio"]:checked +div {
    background: #4152B8;
    color: #fff;
}

.radio-card input[type="radio"]:checked + div .container-active{
    display: flex !important;
    color: #4152B8;
}

.radio-card input[type="radio"]:checked + div .unchecked-container{
    display: none;
}

.check-card {
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15);
    color: #000;
    background: #fff;
    margin: 10px;   
}

.check-card input[type="checkbox"]{
    position: absolute;
   visibility: hidden;
}

.check-card .container-active{
    display: none;
}
.check-card .unchecked-container{
    display: block;
}

.check-card input[type="checkbox"]:checked +div {
    background: #4152B8;
    color: #fff;
}

.check-card input[type="checkbox"]:checked + div .container-active{
    display: flex !important;
    color: #4152B8;
}

.check-card input[type="checkbox"]:checked + div .unchecked-container{
    display: none;
}

.inactive-box-container {
    background: #fff;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15);

    .label {
        color: #000;
        font-weight: 600
    }
}

.container-active {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #4152B8;
    background: #FFF;

    span {
        color: #4152B8;
        font-size: 22px;
    }
}

.unchecked-container {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #4152B8;
}

.save-btn {
    background: #5057C3;
    box-shadow: 0px 8px 22px 6px rgba(0, 0, 0, 0.24), inset 0px 4px 4px rgba(220, 220, 220, 0.25);
    color: white;
    width: 150px;
    height: 50px;
    font-family: Roboto;
    font-size: 16px;
    border-radius: 5px;
}

.check-icon {
    font-size: 22px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  
   opacity: 1;
}
</style>