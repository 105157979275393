<template>
    <NoButtonModal :open="open" @close="$emit('cancel')">
        <div class="d-grid justify-content-center align-items-center">
            <div class="d-flex justify-content-center border-dark" style="border-radius: 50%;">
                <div class="p-2 d-flex" style="color: #AC2734; border: 2px solid #AC2734; border-radius: 50%;">
                    <span class="material-symbols-outlined">delete</span>
                </div>
            </div>
            <div class="d-flex justify-content-center flex-direction-row py-2">
                <span style="font-weight: 500; font-size: 20px; font-family: 'Roboto';">Are you sure to delete this tag</span>
            </div>
            <div class="d-flex justify-content-center flex-direction-row py-2">
                <span style="font-weight: 400; font-size: 15px; font-family: 'Roboto';">This tag will be impacting to entire people of this group</span>
            </div>
            <div class="d-flex justify-content-center">
                <button class="rounded-3" style="background: #334DAB; color: #FFF; font-size: 15px;" @click="alertResponse('handleDelete')">Confirm</button>
                <button class="rounded-3" style="border: 1px solid #AC2734; color: #AC2734; font-size: 15px;" @click="alertResponse('cancel')">Cancel</button>
            </div>
        </div>
        <!-- <button class="close-corner-x" type="button" @click.prevent="alertResponse('cancel')">
            <span class="material-icons-outlined dark-text">close</span>
        </button>
        <h1>Delete Alert</h1>
        <p>Are you sure you want to delete this link?</p>
        <button @click="alertResponse('cancel')" class="button secondary">Cancel</button>
        <button @click="alertResponse('handleDelete')" class="button primary">Delete</button> -->
    </NoButtonModal>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    export default {
        name: 'DeleteTagAlert',
        components: { NoButtonModal },
        props: {
            open: {
                type: Boolean,
                required: true,
            }
        },
        methods: {
            alertResponse(action) {
                if( action === 'handleDelete' ){
                    this.$emit('handleDelete');
                }
                else {
                    this.$emit('cancel');
                }
            }
        }
    };
</script>
