<template>
    <div class="tab-contanier">
        <Loading v-if="isLoading" />
        <form @submit.prevent="sendMessage">
            <div class="d-flex my-3" style="gap: 20px">
                <Multiselect :options="applicationList" :allow-empty="false" :searchable="true" :show-labels="false"
                    :placeholder="'Applications *'" label="dashboardType" track-by="id" v-model="application" />
                <Multiselect :options="dashboardList" :allow-empty="false" :searchable="true" :show-labels="false"
                    :placeholder="'Dashboards *'" label="dashboardName" track-by="id" v-model="dashboard"
                    @input="changeDashboard($event.id)" :disabled="application == ''" />
                <Multiselect :options="membersList" :searchable="true" :show-labels="true" :multiple="true"
                    :placeholder="'Group of members *'" label="first_name" :custom-label="fullName" track-by="id"
                    v-model="members" :disabled="dashboard == ''" :clear-on-select="false" :close-on-select="false"
                    :limit="1" :limit-text="limitText" />
            </div>
            <div class="py-2">
                <label for="message" class="fw-bolder fs-5 py-2 text-dark"> Create Messages *</label>
                <TextArea :name="'message'" :id="'message'" :inputClass="'top-5'" :rows="4" :cols="50" :limit="2000"
                    :placeholder="'Write your message'" v-model="message" />
            </div>
            <div class="w-100 d-flex my-3" style="gap: 10px">
                <!-- <div class="d-flex flex-column w-30 py-2 px-2 input-field">
                    <input id="url" v-model="url" placeholder="Enter URL" autocomplete="off" />
                </div> -->
                <div class="w-33 date-time-wrap">
                    <div v-if="!isLoading">
                        <ejs-datetimepicker v-model="startDateTime" :min="minDate" placeholder="Start Date & Time"
                            id="startDateandtime" />
                    </div>
                    <!-- <div v-else>
                        <ejs-datetimepicker placeholder="Start Date & Time" />
                    </div> -->
                </div>
                <div class="w-33 date-time-wrap">
                    <div v-if="!isLoading">
                        <ejs-datetimepicker v-model="endDateTime" id="endDateandtime" :min="handleMinDate"
                            placeholder="End Date & Time *" />
                    </div>
                    <!-- <div v-else>
                        <ejs-datetimepicker placeholder="End Date & Time *" />
                    </div> -->
                </div>
                <div v-if="isEdit" class="w-30 d-flex justify-content-center">
                    <button class="btn-danger button primary" type="button" @click.prevent="cancelEdit">Cancel</button>
                    <button class="blue-btn" type="submit">Save Changes</button>
                </div>
                <div v-else class="w-30 d-flex justify-content-end">
                    <button class="light-btn" :class="!isMessageScheduled ? 'disabled-button' : ''"
                        :style="{ background: !isMessageScheduled ? 'white !important' : '' }"
                        :disabled="!isMessageScheduled" type="submit">Schedule</button>
                    <button class="blue-btn" :class="isMessageScheduled ? 'disabled-button' : ''"
                        :style="{ background: isMessageScheduled ? '#5057c3 !important' : '' }"
                        :disabled="isMessageScheduled" type="submit">Send
                        Message</button>
                </div>
            </div>
        </form>
        <div class="table-container mt-5">
            <div class="w-100 rounded-4">
                <h1 class="px-5 py-3 page-heading" style="background: #f1f7ff">Message History</h1>
            </div>
            <div v-if="!messagesList" class="fs-4 text-bolder d-flex align-items-center justify-content-center"
                style="min-height: 200px;">
                Loading...</div>
            <div v-if="messagesList && messagesList.length > 0" class="profile-listing-body px-2 pb-2">
                <b-table id="my-table" :items="messagesList" :fields="fields" :current-page="currentPage"
                    :per-page="perPage" bordered responsive>
                    <template #cell(message_summary)="row">
                        <div v-if="row.item">{{ row.item.message }}</div>
                    </template>
                    <template #cell(start_date)="row">
                        <div v-if="row.item">{{ formattedDate(row.item.startTime) }}</div>
                    </template>
                    <template #cell(end_date)="row">
                        <div v-if="row.item">{{ formattedDate(row.item.endTime) }}</div>
                    </template>
                    <template #cell(status)="row">
                        <button v-if="row.item.endTime < new Date().toISOString()" class="expired-btn">Expired</button>
                        <button v-else-if="row.item.startTime > new Date().toISOString()"
                            class="schedule-btn">Scheduled</button>
                        <button v-else class="success-btn">Message sent</button>
                    </template>
                    <template #cell(action)="row">
                        <div class="d-flex justify-content-center align-items-center" style="gap: 20px">
                            <b-tooltip :target="`${row.item.messageId}${row.item.creationTime}edit`" title="Edit"
                                triggers="hover" />
                            <b-tooltip :target="`${row.item.messageId}${row.item.creationTime}delete`" title="Delete"
                                triggers="hover" />
                            <img src="@/assets/px/edit-icon.png" alt="" style="height: 20px"
                                :id="`${row.item.messageId}${row.item.creationTime}edit`" @click="editClick(row.item)" />
                            <img src="@/assets/px/delete-icon.png" alt="" style="height: 20px"
                                :id="`${row.item.messageId}${row.item.creationTime}delete`"
                                @click="deleteClick(row.item)" />
                        </div>
                    </template>
                </b-table>
                <div class="b-table-pagination">
                    <div class="pagination-count-per-page">
                        <div class="pagination-number-per-page">
                            <div>
                                <span>Per page:</span>
                            </div>
                            <div>
                                <select style="" @change="changeCountPerPage" :value="perPage">
                                    <option v-for="item in perPageOptions" :key="item" :value="item">
                                        {{ item }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <b-pagination align="right" v-model="currentPage" :total-rows="rows" :per-page="perPage"
                        aria-controls="my-table" first-text="First" prev-text="Prev" next-text="Next"
                        last-text="Last"></b-pagination>
                </div>
            </div>
            <div v-else-if="messagesList && messagesList.length == 0"
                class="fs-4 text-bolder d-flex align-items-center justify-content-center" style="min-height: 200px;">No
                messages created</div>
        </div>
        <DeleteMessageModal v-if="showDeleteAlert" :open="showDeleteAlert" :loading="isLoading"
            @cancel="showDeleteAlert = false" @handleDelete="confirmDelete" />
    </div>
</template>

<script>
import { clients, dashboardBuilder } from '@/util/apiRequests';
import { DateTimePickerPlugin } from '@syncfusion/ej2-vue-calendars';
import DeleteMessageModal from './DeleteMessageModal.vue';
import {
    createKioskMessage,
    getAllKioskMessages,
    deleteKioskMessage,
    updateKioskMessage,
} from '../../util/helpers/graphql/KioskNotificationService';
import Loading from '@/components/general/loading/loading';
import { tryGetFilter } from '@/util/tryGetFilter';
import dayjs from '@/util/dayjs';
import Vue from 'vue';

Vue.use(DateTimePickerPlugin);

export default {
    name: 'SendMessages',
    data() {
        return {
            application: '',
            dashboard: '',
            members: [],
            message: '',
            startDateTime: null,
            endDateTime: null,
            url: '',
            showDeleteAlert: false,
            dashboardList: [],
            allMembersList: [],
            membersList: [],
            messagesList: null,
            messageDetails: null,
            isEdit: false,
            minDate: new Date(),
            isMessageSended: false,
            isLoading: true,
            applicationList: [
                {
                    id: 0,
                    dashboardType: 'Kiosk',
                },
            ],
            fields: [
                { key: 'message', label: 'Message', tdClass: "text-left" },
                { key: 'dashboard', label: 'Dashboard Name' },
                { key: 'start_date', label: 'Start Date' },
                { key: 'end_date', label: 'End Date' },
                { key: 'status', label: 'Status' },
                { key: 'action', label: 'Action' },
            ],

            // b-table properties
            storeKey: 'MessageHistory',
            perPageOptions: [5, 10, 20, 50, 100],
            currentPage: 1,
            perPage: 10,
            totalRows: null,
        };
    },
    components: { DeleteMessageModal, Loading },
    computed: {
        rows() {
            return this.totalRows;
        },
        isMessageScheduled() {
            const currentTime = new Date().toISOString();
            if (!this.startDateTime) return false;
            return this.startDateTime.toISOString() > currentTime;
        },
        handleMinDate() {
            if (this.startDateTime > this.minDate) {
                const timeToRound = new Date(this.startDateTime);
                const minutes = timeToRound.getMinutes();
                const roundedMinutes = Math.round(minutes / 30) * 30;
                timeToRound.setMinutes(roundedMinutes, 0, 0);
                if (timeToRound < this.startDateTime) {
                    timeToRound.setMinutes(timeToRound.getMinutes() + 30);
                }
                return timeToRound;
            }
            return this.minDate;
        }
    },
    methods: {
        async getAllDashboards() {
            try {
                let response = await this.$api.get(dashboardBuilder.getTemplateList('completed'));
                const { templateMetaList } = response.data;

                this.dashboardList = templateMetaList.filter((dashboard) => {
                    return dashboard.application == 'kiosk'
                });

            } catch (err) {
                this.$toasted.error('Failed to retrieve Dashboards');
            }
        },
        async getAllMembers() {
            try {
                //const res = await this.$api.get(clients.getClientsCount())
                const res = await this.$api.get(clients.getAllClientsCount())
                if (res.status === 200) {
                    //const { activeClients } = res.data.data

                    const activeClients = res.data.data.clients;
                    let body = {
                        criteria: {
                            page: {
                                num_per_page: activeClients,
                            }
                        }
                    }

                    let response = await this.$api.post(clients.getClientsList(), body);

                    this.allMembersList = response.data.rows;
                }
                else {
                    this.$toasted.error('Failed to retrieve Members');
                }

            } catch (error) {
                this.$toasted.error('Failed to retrieve Members');
            }
        },
        async getAllMessages() {
            try {
                let companyId = this.$store.state.user.company_id;
                const response = await getAllKioskMessages(companyId);
                if (response) {
                    const { getAllKioskNotifications } = response ;

                    getAllKioskNotifications?.forEach((message, idx, dummy) => {
                        const dashboard = this.dashboardList.find((dashboard) => {
                            return dashboard.id == message.messageId
                        });
                        dummy[idx].dashboard = dashboard?.dashboardName;
                    })
                    this.messagesList = getAllKioskNotifications ?? [];
                    this.totalRows = getAllKioskNotifications?.length ?? 0;
                }
            }
            catch (err) {
           console.log(err);
                this.$toasted.error('Failed to retrieve Messages');
            }
            finally {
                this.minDate.setHours(0, 0, 0, 0);
                this.isLoading = false;
                this.application = '';
                this.dashboard = '';
                this.members = [];
                this.message = '';
                this.url = '';
                this.startDateTime = null;
                this.endDateTime = null;
            }
        },
        async sendMessage() {
            if (this.isMessageSended) return;
            if (!this.application || !this.dashboard || !this.members?.length || !this.message || !this.endDateTime) {
                this.$toasted.error('Please fill all required fields');
                return;
            }
            if (this.startDateTime > this.endDateTime) {
                this.$toasted.error('Start date should be less than End date');
                return;
            }
            this.isMessageSended = true;
            this.isLoading = true;
            try {
                const startDate = this.startDateTime ? this.startDateTime.toISOString() : new Date().toISOString();
                const endDate = this.endDateTime.toISOString();
                let membersId = this.members.map((member) => member.id + '');
                let companyId = this.$store.state.user.company_id;
                var response;
                if (this.isEdit) {
                    let deletedMembersId = this.messageDetails.users.filter(userId => !membersId.includes(userId))
                    membersId = membersId.filter(user => !deletedMembersId.includes(user));
                    if (deletedMembersId.length) {
                        this.messageDetails.users = deletedMembersId;
                        await this.deleteMessage(this.messageDetails);
                        membersId = membersId.filter(memberId => !this.messageDetails.users.includes(memberId))
                    }
                    response = await updateKioskMessage(this.dashboard.id, this.message, startDate,companyId, endDate, this.messageDetails.creationTime, membersId)
                    this.isEdit = false;
                    this.messageDetails = null;
                    this.$toasted.success("Message Updated Successfully!")
                }
                else {
                    response = await createKioskMessage(this.dashboard.id, this.message, startDate,companyId, endDate, membersId);
                    if (startDate >= new Date().toISOString()) {
                        this.$toasted.success("Message Scheduled Successfully!")
                    } else {
                        this.$toasted.success("Message Sent Successfully!")
                    }

                }
                if (response) {
                    await this.getAllMessages();
                    this.isMessageSended = false;
                }
            } catch (err) {
                this.$toasted.error('Failed to send message');
                this.isMessageSended = false;
                this.isLoading = false;
            }
        },
        async deleteMessage(item) {
            try {
                const response = await deleteKioskMessage(item.messageId, item.creationTime, item.users);
                if (response) {
                    if (this.isEdit) {
                        return;
                    }
                    await this.getAllMessages();
                    this.$toasted.success("Message Deleted Successfully!");
                }
            } catch (err) {
                this.$toasted.error('Failed to delete message');
            }
            finally {
                this.showDeleteAlert = false;
            }
        },
        formattedDate(date) {
            // return date ? dayjs(date).format('MM/DD/YYYY, hh:mm A') : '';
            return date ? dayjs(date).format('MM/DD/YYYY') : '';
        },
        fullName(item) {
            return `${item.first_name} ${item.last_name}`;
        },
        limitText() {
            return `and ${this.members.length - 1} others`
        },
        changeDashboard(dashboardId) {
            let tempMembersList = this.allMembersList.filter((member) => {
                return member.dashboard_used == dashboardId
            });
            if (!this.isEdit) {
                const tempSet = new Set();

                this.messagesList.forEach(message => {
                    if (message.messageId == dashboardId && !(message.endTime <= new Date().toISOString())) {
                        message.users.forEach(user => {
                            const foundMember = tempMembersList.find(member => member.id == user);
                            if (foundMember && !tempSet.has(foundMember)) {
                                tempSet.add(foundMember);
                            }
                        });
                    }
                });

                tempMembersList = tempMembersList.filter(member => !tempSet.has(member));
            }
            this.members = [];
            this.membersList = tempMembersList;
            if (!tempMembersList.length) {
                this.$toasted.error('No members available with this Dashboard');
            }
        },
        editClick(item) {
            window.scrollTo(0, 0);
            this.isEdit = true;
            this.application = {
                id: 0,
                dashboardType: "kiosk"
            }
            this.messageDetails = item;
            this.message = item.message;
            this.startDateTime = item.startTime;
            this.endDateTime = item.endTime;
            this.dashboard = this.dashboardList.find((dashboard) => {
                return dashboard.id == item.messageId;
            });
            this.members = this.allMembersList.filter((member) => {
                return item.users.includes(member.id.toString());
            })
            this.membersList = this.allMembersList.filter((member) => {
                return member.dashboard_used == item.messageId;
            });
        },
        cancelEdit() {
            this.isLoading = true;
            this.minDate.setHours(0, 0, 0, 0);
            this.application = '';
            this.dashboard = '';
            this.members = [];
            this.message = '';
            this.url = '';
            this.startDateTime = '';
            this.endDateTime = '';
            this.isEdit = false;
            setTimeout(() => {    // It is intentionally added to re-render dateAndTime-picker when cancel the edit message 
                this.isLoading = false;
            }, 1000);
        },
        deleteClick(item) {
            this.messageDetails = item;
            this.showDeleteAlert = true;
        },
        async confirmDelete() {
            this.showDeleteAlert = false;
            this.isLoading = true;
            await this.deleteMessage(this.messageDetails);
        },
        changeCountPerPage({ target: { value } }) {
            this.perPage = Number(value);
        },
        savedPagination() {
            const previousPagination = tryGetFilter(this.$store, this.storeKey);
            this.currentPage = previousPagination.currentPage ?? 1;
            this.perPage = previousPagination.perPage ?? 10;
        },
    },
    async created() {
        this.savedPagination();
        await this.getAllDashboards();
        await this.getAllMembers();
        await this.getAllMessages();
    },
    watch: {
        currentPage: function (val) {
            this.$store.commit('filters/updateFilter', {
                stateKey: this.storeKey,
                criteria: {
                    currentPage: Number(val),
                    perPage: Number(this.perPage),
                }
            });
        },
        perPage: function (val) {
            this.$store.commit('filters/updateFilter', {
                stateKey: this.storeKey,
                criteria: {
                    perPage: Number(val),
                    currentPage: Number(this.currentPage),
                }
            });
        },
    },
};
</script>

<style>
.tab-contanier {
    border-radius: 8px;
    border: 1px solid #d8d8df;
    background: #fff;
    padding: 20px;
}

.light-btn,
.light-btn:hover {
    background: #fff;
    color: #5057c3;
    width: 120px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px;
    border: 2px solid #5057c3;
}

.blue-btn,
.blue-btn:hover {
    box-shadow: 0px 8px 22px 6px rgba(0, 0, 0, 0.24), inset 0px 4px 4px rgba(220, 220, 220, 0.25);
    background: #5057c3;
    color: white;
    width: max-content;
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px;
}

.btn-danger {
    width: 120px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px !important;
}

.disabled-button {
    opacity: 0.6;
}

.input-field {
    border: 1px solid #949494;
    border-radius: 5px;
    justify-content: center;
    margin-bottom: 4px;
}

input,
input:focus {
    outline: none;
    border: none;
}

.date-time-wrap .e-input-group:not(.e-float-icon-left) {
    border-width: 1px !important;
    padding: 6px 12px;
    border-radius: 6px;
}

.table-container {
    border-radius: 27px;
    border: 1px solid #ebeff4;
}

.success-btn {
    border: 2px solid #48b99a;
    background-color: white;
    color: #48b99a;
    border-radius: 4px;
    font-weight: 500;
    width: 140px;
}

.schedule-btn {
    border: 2px solid #1f3bb3;
    background-color: white;
    color: #1f3bb3;
    border-radius: 4px;
    font-weight: 500;
    width: 140px;
}

.expired-btn {
    border: 2px solid #D21414;
    background-color: white;
    color: #D21414;
    border-radius: 4px;
    font-weight: 500;
    width: 140px;
}
</style>
