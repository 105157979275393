<template>
    <div class="custom-list-grid-standard h-100">
        <div class="d-flex justify-content-between bg-white px-4 py-2 rounded-3">
            <div class="d-flex p-5 mx-2 clients-section w-50">
                <div class="d-flex">
                    <img src="@/assets/photos/bot_1.png" alt="" class="w-75 p-1" style="height: 110px;">
                </div>
                <div class="d-grid">
                    <span class="head-title">Members Automatic Logoff</span>
                    <p class="desc">Auto log off members from the MobexHealth Hub Portal after a set period of time (does not apply to tablet app).</p>
                    <div>
                        <!-- v-model="clientsAutoLogoff" -->
                        <select id="client-auto-logoff" class="w-50 position-relative" style="line-height: 20px;" disabled="true">
                            <option value="" selected disabled> Select </option>
                            <option value="15"> 15 minutes </option>
                            <option value="30"> 30 minutes </option>
                            <option value="60"> 60 minutes </option>
                            <option value="120"> 120 minutes </option>
                            <option value="180"> 180 minutes </option>
                        </select>
                    </div>
                    
                    <button class="w-33 my-4 rounded-3 client-save-btn" @click="saveChanges('client')" disabled="true" style="background: lightgray;">Save</button>
                </div>
            </div>
            <div class="d-flex p-5 mx-2 users-section w-50">
                <div class="d-flex">
                    <img src="@/assets/photos/bot_2.png" alt="" class="w-75 p-1" style="height: 110px;">
                </div>
                <div class="d-grid">
                    <span class="head-title">Users Automatic Logoff</span>
                    <p class="desc">Auto log off users from the MobexHealth Hub Portal after a set period of time.</p>
                    <div>
                        <select id="user-auto-logoff" class="w-50 position-relative" style="line-height: 20px;" v-model="usersAutoLogoff">
                            <option value=""> Select </option>
                            <option value="15"> 15 minutes </option>
                            <option value="30"> 30 minutes </option>
                            <option value="60"> 60 minutes </option>
                            <option value="120"> 120 minutes </option>
                            <option value="180"> 180 minutes </option>
                        </select>
                    </div>
                    <button class="w-33 my-4 rounded-3 user-save-btn" @click="saveChanges('user')">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { settings } from '@/util/apiRequests';

    const api_root = 'settings';

    export default {
        components: {},
        name: 'UsersAndClientsSetting',
        props: {
            level: {
                type: String,
            },
            level_id: {
                type: Number,
                default: 1,
            },
            data_source: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                usersAutoLogoffSetting: null,
                clientsAutoLogoffSettings: null,
                usersAutoLogoff: null,
                clientsAutoLogoff: null
            };
        },
        methods: {
            async saveChanges( userType ) {
                const data = [];
                let message = {};
                if( userType === 'client' ) {
                    data.push(
                        {
                            id: this.clientsAutoLogoffSettings.data_id,
                            val: this.clientsAutoLogoff
                        }
                    );
                    message['success'] = "Client's Log Off Settings Updated !";
                    message['error'] = "Failed To Update Client's Log Off Settings !";
                    if( !this.clientsAutoLogoffSettings.data_id ) {
                        this.clientsAutoLogoffSettings.data_id = await this.this.createSettingsData( this.level, this.level_id, this.clientsAutoLogoffSettings.settings_id, this.clientsAutoLogoff );
                        this.$toasted.success(message['success']);
                        return;
                    }
                }
                else if( userType === 'user' ) {
                    data.push(
                        {
                            id: this.usersAutoLogoffSettings.data_id,
                            val: this.usersAutoLogoff
                        }
                    );
                    message['success'] = "User's Log Off Settings Updated !";
                    message['error'] = "Failed To Update User's Log Off Settings !";
                    if( !this.usersAutoLogoffSettings.data_id ) {
                        this.usersAutoLogoffSettings.data_id = await this.createSettingsData( this.level, this.level_id, this.usersAutoLogoffSettings.settings_id, this.usersAutoLogoff );
                        this.$toasted.success(message['success']);
                        return;
                    }
                }
                const endpoint = settings.updateMultipleSettings();
                const updateTagsResponse = await this.$api.put(endpoint, {updateRequests: data});
                if( updateTagsResponse.data.success ) {
                    this.$toasted.success(message['success']);
                }
                else {
                    this.$toasted.error(message['error']);
                }
            },
            async createSettingsData(level, levelId, settingsId, value) {
                const settingCreated = await this.$api.post(settings.createSettingData(level, levelId, settingsId), {val: value});
                return settingCreated.data.recId[0]
            }
        },
        async created() { 
            const [ clients_setting, users_setting ] = this.data_source;
            this.clientsAutoLogoffSettings = this.data_source.filter( el => el.settings_id === 171 )[0];
            this.usersAutoLogoffSettings = this.data_source.filter( el => el.settings_id === 170 )[0];

            this.clientsAutoLogoff = clients_setting.value;
            this.usersAutoLogoff = users_setting.value;
        },
    };
</script>
<style scoped>

    .up-arrow {
        margin-left: -20px;
        margin-top: 3px; 
        background: #EDF2FF; 
        font-size: 15px;
    }

    .down-arrow {
        margin-left: -20px;
        margin-top: 18px;
        background: #EDF2FF;
        font-size: 15px;
    }

    .client-save-btn:hover {
        cursor: no-drop;
        background: lightgray;
    }

    @media only screen and (min-width: 1250px) {
        .clients-section {
            background: #D7F1FD; 
            border: 2px solid #2382FB; 
            height: 200px
        }

        .users-section {
            background: #FFEFEF; 
            border: 2px solid #AC2734; 
            height: 200px
        }

        .head-title {
            font-weight: bold; 
            font-size: 22px;
        }

        .desc {
            font-size: 17px; 
            font-family: 'Roboto';
        }

        .client-save-btn {
            height: 45px; 
            font-family: 'Roboto'; 
            font-size: 18px; 
            background: #5057C3; 
            color: #FFF;
        }

        .user-save-btn {
            height: 45px; 
            font-family: 'Roboto'; 
            font-size: 18px; 
            background: #AC2734; 
            color: #FFF;
        }
    }

    @media only screen and (max-width: 1250px) {
        .clients-section {
            background: #FFEFEF; 
            border: 2px solid #2382FB; 
            height: 180px
        }

        .users-section {
            background: #D7F1FD; 
            border: 2px solid #AC2734; 
            height: 180px
        }

        .head-title {
            font-weight: bold; 
            font-size: 18px;
        }

        .desc {
            font-size: 13px; 
            font-family: 'Roboto';
        }

        .client-save-btn {
            height: 40px; 
            font-family: 'Roboto'; 
            font-size: 15px; 
            background: #5057C3; 
            color: #FFF;
        }

        .user-save-btn {
            height: 40px; 
            font-family: 'Roboto'; 
            font-size: 15px; 
            background: #AC2734; 
            color: #FFF;
        }
    }
    
</style>