<template>
    <div class="profile-function-card d-flex justify-content-between align-items-center">
        <span v-if="profileFunction.function_type == 'generic'" class="profile-function-card-label me-3">{{ profileFunction.name }}</span>
        <span v-else class="profile-function-card-label me-3">{{ profileFunction.function_name }}</span>
        <Toggle
            :id="profileFunction.function_name"
            class="toggle-switch"
            labelClass="switch"
            spanClass="slider"
            :value="profileFunction.active"
            @input="(value) => handleToggle(value, profileFunction)"
        />
    </div>
</template>
<script>
    export default {
        name: 'ProfileFunctionCard',
        props: {
            profileFunction: {
                type: Object,
                required: true
            }
        },
        data() {
            return {

            }
        },
        methods: {
            handleToggle(value, profileFunction) {
                this.$emit('onToggle',{value, profileFunction});
            }
        }
    }
</script>