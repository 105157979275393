<template>
    <div class="custom-list-grid-standard">
        <div class="bg-white px-4 py-2 rounded-3">
            <h3 style="font-weight: 700;">Member Tags</h3>
            <p>Tags make it easy to group and search for members. Tags can be created here and assigned to members in their profiles</p>
            <div class="d-flex justify-content-start flex-wrap">
                <div class="d-flex justify-content-center align-items-center flex-wrap px-2 py-1 rounded-2 m-2 tag" v-for="tag in tags" :key="tag">
                    <div class="d-flex align-items-center px-2">{{ tag }}</div>
                    <span class="material-symbols-outlined close" @click="tagToDelete = tag; deleteTriggered = true;"> close </span>
                </div>
            </div>

            <div class="d-flex w-100 my-5" style="color: #FFF;">
                <div class="d-flex align-items-center border-5 px-1 w-15" style="border-radius: -65px; background: #AC2734; cursor: pointer;" @click="addnewTag">
                    <div class="d-flex justify-content-center align-items-center mx-4 my-2 rounded-circle" style="background: rgba(255, 255, 255, 0.3);">
                        <span class="material-symbols-outlined add-icon"> add </span>
                    </div>
                    <div class="d-flex justify-content-center align-items-center my-1">
                        <span class="add-new-tag-label">Add New Tag</span>
                    </div>
                </div>
                <input type="text" class="w-40 px-4 tag-input" id="tag-input" v-model="tagInput" v-on:keyup.enter="addnewTag()" placeholder="Enter tag name here">
                <span class="text-dark mx-3">Press enter to add new tag</span>
            </div>

        </div>
        <!-- <div class="d-flex justify-content-end mx-2 my-4" >
            <button class="px-5 py-3 rounded-2 save-btn" @click="saveChanges">Save Changes</button>
        </div> -->
        <DeleteTagAlert :open="deleteTriggered" @handleDelete="removeTag(); deleteTriggered = false" @cancel="tagToDelete = null; deleteTriggered = false"/>

    </div>
</template>

<script>
    import DeleteTagAlert from '@/components/settings/DeleteTagAlert.vue';
    import { settings } from '@/util/apiRequests';

    const api_root = 'settings';

    export default {
        components: { DeleteTagAlert },
        name: 'TagsSettings',
        props: {
            level: {
                type: String,
            },
            level_id: {
                type: Number,
                default: 1,
            },
            data_source: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                tagInput: null,
                tags: [],
                tagData: null,
                deleteTriggered: false,
                tagToDelete: null
            };
        },
        methods: {
            addnewTag() {
                if( !this.tagInput || !this.tagInput.trim() ) return;
                const i = this.tags.indexOf( this.tagInput.trim() );
                if( i > -1 ) {
                    this.$toasted.error("Please insert unique tag name !");
                    return;
                }
                this.tagInput ? this.tags.push( this.tagInput.trim() ) : ''; 
                this.tagInput = null;
                this.saveChanges();
            },
            removeTag() {
                const i = this.tags.indexOf( this.tagToDelete );
                this.tags.splice(i, 1);
                this.saveChanges();
            },
            async saveChanges() {
                if( !this.tagData.data_id ) {
                    this.tagData.data_id = await this.createSettingsData( this.level, this.level_id, this.tagData.settings_id, this.tags.join(", ") );
                    this.$toasted.success("Member Tags Updated !");
                    return;
                }
                const data = [ 
                    {
                        id: this.tagData.data_id,
                        val: this.tags.join(", ")
                    } 
                ];
                const endpoint = settings.updateMultipleSettings();
                const updateTagsResponse = await this.$api.put(endpoint, {updateRequests: data});
                if( updateTagsResponse.data.success ) {
                    this.$toasted.success("Member Tags Updated !");
                }
                else {
                    this.$toasted.error("Failed to update Member Tags !");
                }
            },
            async createSettingsData(level, levelId, settingsId, value) {
                const settingCreated = await this.$api.post(settings.createSettingData(level, levelId, settingsId), {val: value});
                return settingCreated.data.recId[0]
            }
        },
        async created() {
            const [ tagData ] = this.data_source;
            this.tagData = tagData;
            if( this.tagData.value.trim() ) {
                this.tags = this.tagData.value.split(", ");
            }
        },
    };
</script>
<style scoped>
    .save-btn {
        background: rgb(80, 87, 195);
        color: rgb(255, 255, 255);
        font-family: Roboto;
        font-size: 17px;
        box-shadow: 0px 8px 22px 6px rgba(0, 0, 0, 0.24), inset 0px 4px 4px rgba(220, 220, 220, 0.25);
    }

    .tag {
        border: 2px solid #5056C3; 
        color: #5056C3; 
        font-family: 'Roboto';
    }

    .close {
        font-size: 16px; 
        font-weight: bold;
        cursor: pointer;
    }

    .tag-input {
        border: 2px solid #AC2734; 
        margin-left: -5px; 
        font-family: Roboto; 
        font-weight: 500;
    }

    @media only screen and (min-width: 1600px) {
        .add-new-tag-label {
            font-size: 18px; 
            font-weight: 600; 
            font-family: Roboto; 
            color: rgb(255, 255, 255);
        }
        
        .add-icon {
            font-size: 40px; border-radius: 50%;
        }

    }

    @media only screen and (min-width: 1300px) and (max-width: 1600px) {
        .add-new-tag-label {
            font-size: 15px; 
            font-weight: 600; 
            font-family: Roboto; 
            color: rgb(255, 255, 255);
        }

        .add-icon {
            font-size: 35px; 
            border-radius: 50%;
        }
    }

    @media only screen and (max-width: 1360px) {
        .add-new-tag-label {
            font-size: 12px; 
            font-weight: 600; 
            font-family: Roboto; 
            color: rgb(255, 255, 255);
        }

        .add-icon {
            font-size: 30px; 
            border-radius: 50%;
        }
    }
    
</style>