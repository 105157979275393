import { API } from 'aws-amplify';
import { createKioskNotification, deleteKioskNotification, updateKioskNotification } from '../../graphql/mutations';
import { getAllKioskNotifications } from '../../graphql/queries';

export const getAllKioskMessages = async (companyId) => {
    const { data, errors } = await API.graphql({
        query: getAllKioskNotifications,
        variables: { companyId },
    });
    if (errors) {
        return new Error(errors[0].message);
    }
    return data;
};

export const createKioskMessage = async (messageId, message, startTime,companyId, endTime, users) => {
    const { data, errors } = await API.graphql({
        query: createKioskNotification,
        variables: { messageId, message, startTime,companyId, endTime, users },
    });
    if (errors) {
        return new Error(errors[0].message);
    }
    return data;
};

export const updateKioskMessage = async (messageId, message, startTime,companyId, endTime, creationTime, users) => {
    const { data, errors } = await API.graphql({
        query: updateKioskNotification,
        variables: { messageId, message, startTime,companyId, endTime, creationTime, users },
    });
    if (errors) {
        return new Error(errors[0].message);
    }
    return data;
};

export const deleteKioskMessage = async (messageId, creationTime, users) => {
    const { data, errors } = await API.graphql({
        query: deleteKioskNotification,
        variables: { messageId, creationTime, users },
    });
    if (errors) {
        return new Error(errors[0].message);
    }
    return data;
};
