<template>
    <div style="font-family: 'Roboto';">
        <div class="custom-list-grid-standard h-100 my-4">
            <div class="d-flex align-items-center justify-content-between py-4 px-2" style="background: #FFEFEF; border-radius: 5px;; border: 1px solid #AC2734;">
                <div class="d-flex">
                    <span class="material-symbols-outlined m-2" style="color: #AC2734; font-weight: 700;">
                        warning
                    </span>
                    <div class="d-grid justify-content-center align-items-center">
                        <span style="color: #AC2734; font-weight: 700; font-size: 18px">Missing Documents</span>
                        <span style="color: #AC2734; font-weight: 600; font-size: 14px">If enabled, please choose which documents users should receive alerts about on their dashboard</span>
                    </div>
                </div>
                <div class="d-flex">
                    <span class="label">{{ getDetails('label', 126) }}</span>
                    <Toggle
                        class="toggle-switch"
                        :labelClass="'switch'"
                        :spanClass="'slider'"
                        :name="dataObject['126'].settingsId+''"
                        :id="dataObject['126'].settingsId+''"
                        v-model="dataObject['126'].val"
                    />
                </div>
            </div>
        </div>

        <div class="d-flex my-4">
            <div class="d-grid w-50 p-4" style="margin-right: 20px; border-radius: 5px; border: #D8D8DF; background: #FFF; border: 1px solid #D8D8DF;">
                <h3>BioPsychoSocial</h3>
                <div class="d-flex justify-content-start align-items-center">
                    <label class="my-3 w-50" for="" style="font-size: 14px; font-weight: 600;">
                        {{ getDetails('label', 2) }}
                    </label>
                    <div class="w-50">
                        <Toggle
                        class="toggle-switch"
                        :labelClass="'switch'"
                        :spanClass="'slider'"
                        :name="dataObject['2'].settingsId+''"
                        :id="dataObject['2'].settingsId+''"
                        v-model="dataObject['2'].val"
                    />
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <label class="my-3 w-50" for="" style="font-size: 14px; font-weight: bold;">
                        {{ getDetails('label', 185) }}
                    </label>
                    <div class="w-50">
                        <select class="my-3" :name="dataObject['185'].settingsId+''" :id="dataObject['185'].settingsId+''" style="line-height: 30px;" v-model="dataObject['185'].val">
                            <option :value="op.value" v-for="op in getDetails('value_options', 185)" :key="op.value">{{ op.text }}</option>
                        </select>
                    </div>
                    
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <label class="my-3 w-90" for="" style="font-size: 14px; font-weight: 600;">
                        {{ getDetails('label', 104) }}
                    </label>
                    <div class="w-10">
                        <input type="number" min="0" :name="dataObject['104'].settingsId+''" :id="dataObject['104'].settingsId+''" v-model="dataObject['104'].val">
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <label class="my-3 w-90" for="" style="font-size: 14px; font-weight: 600;">
                        {{ getDetails('label', 96) }}
                    </label>
                    <div class="w-10">
                        <input type="number" min="0" :name="dataObject['96'].settingsId+''" :id="dataObject['96'].settingsId+''" v-model="dataObject['96'].val">
                    </div>
                </div>
            </div>
            <div class="d-grid w-50 p-4" style="margin-left: 20px; border-radius: 5px; border: #D8D8DF; background: #FFF; border: 1px solid #D8D8DF;">
                <h3>Treatment Plan</h3>
                <div class="d-flex justify-content-between align-items-center">
                    <label class="my-3 w-50" for="" style="font-size: 14px; font-weight: 600;">
                        {{ getDetails('label', 4) }}
                    </label>
                    <div class="w-50">
                        <Toggle
                        class="toggle-switch"
                        :labelClass="'switch'"
                        :spanClass="'slider'"
                        :name="dataObject['4'].settingsId+''"
                        :id="dataObject['4'].settingsId+''"
                        v-model="dataObject['4'].val"
                    />
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <label class="my-3 w-90" for="" style="font-size: 14px; font-weight: 600;">
                        {{ getDetails('label', 103) }}
                    </label>
                    <div class="w-10">
                        <input type="number" min="0" :name="dataObject['103'].settingsId+''" :id="dataObject['103'].settingsId+''" v-model="dataObject['103'].val">
                    </div>
                </div>
                <h3>Treatment Plan Review</h3>
                <div class="d-flex justify-content-between align-items-center">
                    <label class="my-3 w-50" for="" style="font-size: 14px; font-weight: 600;">
                        {{ getDetails('label', 141) }}
                    </label>
                    <div class="w-50">
                        <Toggle
                        class="toggle-switch"
                        :labelClass="'switch'"
                        :spanClass="'slider'"
                        :name="dataObject['141'].settingsId+''"
                        :id="dataObject['141'].settingsId+''"
                        v-model="dataObject['141'].val"
                    />
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <label class="my-3 w-90" for="" style="font-size: 14px; font-weight: 600;">
                        {{ getDetails('label', 142) }}
                    </label>
                    <div class="w-10">
                        <input type="number" min="0" :name="dataObject['142'].settingsId+''" :id="dataObject['142'].settingsId+''" v-model="dataObject['142'].val">
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex my-4">
            <div class="d-grid justify-content-start w-60 p-4" style="margin-right: 20px; border-radius: 5px; border: #D8D8DF; background: #FFF; border: 1px solid #D8D8DF;">
                <h3>Missing Progress Note</h3>
                <div class="d-flex justify-content-between align-items-center">
                    <label class="my-3 w-50" for="" style="font-size: 14px; font-weight: 600;">
                        {{ getDetails('label', 1) }}
                    </label>
                    <div class="w-50">
                        <Toggle
                        class="toggle-switch"
                        :labelClass="'switch'"
                        :spanClass="'slider'"
                        :name="dataObject['1'].settingsId+''"
                        :id="dataObject['1'].settingsId+''"
                        v-model="dataObject['1'].val"
                    />
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <label class="my-3 w-50" for="" style="font-size: 14px; font-weight: 600;">
                        {{ getDetails('label', 173) }}
                    </label>
                    <div class="w-50">
                        <Toggle
                        class="toggle-switch"
                        :labelClass="'switch'"
                        :spanClass="'slider'"
                        :name="dataObject['173'].settingsId+''"
                        :id="dataObject['173'].settingsId+''"
                        v-model="dataObject['173'].val"
                    />
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <label class="my-3 w-90" for="" style="font-size: 14px; font-weight: 600;">
                        {{ getDetails('label', 106) }}
                    </label>
                    <div class="w-10">
                        <input type="number" min="0" :name="dataObject['106'].settingsId+''" :id="dataObject['106'].settingsId+''" v-model="dataObject['106'].val">
                    </div>
                </div>
            </div>
            <div class="d-grid justify-content-start w-40 p-4" style="margin-left: 20px; border-radius: 5px; border: #D8D8DF; background: #5657BE; border: 1px solid #D8D8DF;">
                <h3 style="color: #FFF;">Ready to Send Alerts</h3>
                <div class="d-grid justify-content-between align-items-center">
                    <label class="my-3 w-50" for="" style="font-size: 14px; font-weight: 600; color: #FFF;">
                        {{ getDetails('label', 139) }}
                    </label>
                    <div class="w-50">
                        <Toggle
                        class="toggle-switch"
                        :labelClass="'switch'"
                        :spanClass="'slider switch-extended'"
                        :name="dataObject['139'].settingsId+''"
                        :id="dataObject['139'].settingsId+''"
                        v-model="dataObject['139'].val"
                    />
                    </div>
                </div>
            </div>
        </div>

        <div class="d-grid my-4 p-4" style="border-radius: 5px; border: #D8D8DF; background: #FFF; border: 1px solid #D8D8DF;">
            <h3>Member Balance</h3>
            <div class="d-flex justify-content-start align-items-center w-50">
                <label class="my-3" for="" style="font-size: 14px; font-weight: 600;">
                    {{ getDetails('label', 7) }}
                </label>
                <div>
                    <Toggle
                    class="toggle-switch"
                    :labelClass="'switch'"
                    :spanClass="'slider'"
                    :name="dataObject['7'].settingsId+''"
                    :id="dataObject['7'].settingsId+''"
                    v-model="dataObject['7'].val"
                />
                </div>
            </div>
            <div class="d-flex">
                <div class="d-flex justify-content-between align-items-center w-50" style="margin-right: 20px;">
                    <label class="my-3 w-90" for="" style="font-size: 14px; font-weight: 600;">
                        {{ getDetails('label', 109) }}
                    </label>
                    <div class="w-10">
                        <CurrencyInput
                            label=""
                            :id="dataObject['109'].settingsId+''"
                            :name="dataObject['109'].settingsId+''"
                            :initialValue="parseInt( dataObject['109'].val )"
                            @currency="dataObject['109'].val = $getNumFromCurrency($event)"
                        />
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center w-50" style="margin-left: 20px;">
                    <label class="my-3 w-90" for="" style="font-size: 14px; font-weight: 600;">
                        {{ getDetails('label', 108) }}
                    </label>
                    <div class="w-10">
                        <input type="number" min="0" :name="dataObject['108'].settingsId+''" :id="dataObject['108'].settingsId+''" v-model="dataObject['108'].val">
                    </div>
                </div>
            </div>
        </div>
        
        <div class="d-flex my-4">
            <div class="d-grid justify-content-start w-50 p-4" style="margin-right: 20px; border-radius: 5px; border: #D8D8DF; background: #FFF; border: 1px solid #D8D8DF;">
                <h3>Member Actions</h3>
                <div class="d-flex justify-content-between align-items-center">
                    <label class="my-3 w-50" for="" style="font-size: 14px; font-weight: 600;">
                        {{ getDetails('label', 127) }}
                    </label>
                    <div class="w-50">
                        <Toggle
                        class="toggle-switch"
                        :labelClass="'switch'"
                        :spanClass="'slider'"
                        :name="dataObject['127'].settingsId+''"
                        :id="dataObject['127'].settingsId+''"
                        v-model="dataObject['127'].val"
                    />
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <label class="my-3 w-90" for="" style="font-size: 14px; font-weight: 600;">
                        {{ getDetails('label', 143) }}
                    </label>
                    <div class="w-10">
                        <input type="number" min="0" :name="dataObject['143'].settingsId+''" :id="dataObject['143'].settingsId+''" v-model="dataObject['143'].val">
                    </div>
                </div>
            </div>
            <div class="d-grid justify-content-start w-50 p-4" style="margin-left: 20px; border-radius: 5px; border: #D8D8DF; background: #FFF; border: 1px solid #D8D8DF;">
                <h3>Supervisor Signature</h3>
                <div class="d-flex justify-content-between align-items-center">
                    <label class="my-3" for="" style="font-size: 14px; font-weight: 600;">
                        {{ getDetails('label', 165) }}
                    </label>
                    <div>
                        <Toggle
                        class="toggle-switch"
                        :labelClass="'switch'"
                        :spanClass="'slider'"
                        :name="dataObject['165'].settingsId+''"
                        :id="dataObject['165'].settingsId+''"
                        v-model="dataObject['165'].val"
                    />
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <label class="my-3 w-90" for="" style="font-size: 14px; font-weight: 600;">
                        {{ getDetails('label', 166) }}
                    </label>
                    <div class="w-10">
                        <input type="number" min="0" :name="dataObject['166'].settingsId+''" :id="dataObject['166'].settingsId+''" v-model="dataObject['166'].val">
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-end my-4">
            <button class="border-3 save-btn" @click="saveChanges()">Save Changes</button>
        </div>
    </div>
</template>

<script>
import { settings } from '@/util/apiRequests';
import CurrencyInput from '@/components/general/inputs/CurrencyInput';

export default {
    components: { CurrencyInput },
    name: 'AlertsSetting',
    props: {
        level: {
            type: String,
        },
        level_id: {
            type: Number,
            default: 1,
        },
        data_source: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            dataObject: null
        };
    },
    methods: {
        async saveChanges() {
            let data = Object.values( this.dataObject );
            data = await Promise.all( data.map( async( el ) => {
                if( !el.id ) 
                    el.settingsId = await this.createSettingsData( this.level, this.level_id, el.settingsId, el.val );
                return el;
            } ) )
            const endpoint = settings.updateMultipleSettings();
            const updateTagsResponse = await this.$api.put(endpoint, { updateRequests: data });
            if( updateTagsResponse.data.success ) {
                this.$toasted.success("Alerts Settings Updated !");
            }
            else {
                this.$toasted.error("Failed to update alerts settings !");
            }
        },
        getDetails(type, settingsId, jsonParse) {
            const index = this.data_source.findIndex(el => el.settings_id === settingsId);
            if (type === 'value_options') {
                return this.data_source[index] && this.data_source[index][type] ? JSON.parse(this.data_source[index][type]) : [];
            }
            if(type === 'value' && jsonParse) {
                return JSON.parse(this.dataObject['188'].val);
            }
            return this.data_source[index] ? this.data_source[index][type] : '';
        },
        async createSettingsData(level, levelId, settingsId, value) {
            const settingCreated = await this.$api.post(settings.createSettingData(level, levelId, settingsId), {val: value});
            return settingCreated.data.recId[0]
        }
    },
    async created() {
        const modifiedDataSource = this.data_source.map(el => {
            return {
                settingsId: el.settings_id,
                id: el.data_id,
                val: el.value
            }
        })

        this.dataObject = {};
        modifiedDataSource.forEach(el => {
            Object.assign(this.dataObject, {
                [el.settingsId]: el
            })

            setTimeout(() => {
                document.getElementById(el.settingsId)
                .addEventListener('input', e => {
                    const el = e.target || e

                    if(el.type == "number" && el.min ){
                        let value = parseInt(el.value)
                        el.value = value;
                        let min = parseInt(el.min)
                        if ( value < min ) el.value = el.min
                    }
                });
            }, 100)
        })
    },
};
</script>
<style scoped>

.check-icon {
    font-size: 22px;
}

.save-btn {
    background: #5057C3;
    box-shadow: 0px 8px 22px 6px rgba(0, 0, 0, 0.24), inset 0px 4px 4px rgba(220, 220, 220, 0.25);
    color: white;
    width: 150px;
    height: 50px;
    font-family: Roboto;
    font-size: 16px;
    border-radius: 5px;
}

.label {
    font-size: 14px;
    font-weight: 700;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  
    opacity: 1;
}

</style>